import React from "react";

const Body1 = () => (
  <section id="firstPage">
    <div className="pageUnique ">
      <div className="title title2 prenom">Marie-Noëlle</div>
      <div className="title title1 nom">HOSTEIN</div>
      <a className="btn" href="#contact">
        Contact
      </a>
    </div>
  </section>
);

export default Body1;
