import React from "react";

const Body6 = () => (
  <section id="journey">
    <div className="container">
      <div className="partieIllus">
        <h1 className="title title1">Mon parcours</h1>
        <img
          className="image illusParcours"
          src="/assets/images/parcoursN.png"
          alt="illustration me representant marchant sur le chemin de la vie"
        ></img>
      </div>

      <div className="partieTexte">
        {/* <!-- The Timeline --> */}
        <ul className="timeline">
          {/* <!-- Item 1 --> */}
          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">Stage chez Kuadrado Software</span>
                <span className="time-wrapper">
                  <span className="time">mai/juin 2022</span>
                </span>
              </div>
              <div className="desc">
                Approfondissement des acquis sur Javascript, Sass. Travail sur
                linux. Découverte de Rust, React, NodeJs et de Docker.
              </div>
            </div>
          </li>

          {/* <!-- Item 2 --> */}
          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">Assistante d'Education</span>
                <span className="time-wrapper">
                  <span className="time">2013 - 2020</span>
                </span>
              </div>
              <div className="desc">
                Collège de l'Eyrieux - Saint Sauveur de Montagut
              </div>
            </div>
          </li>

          {/* <!-- Item 3 --> */}
          <li>
            <div className="direction-l">
              <div className="flag-wrapper">
                <span className="flag">Congé Parental</span>
                <span className="time-wrapper">
                  <span className="time">2008 - 2013</span>
                </span>
              </div>
              <div className="desc">
                J'ai pris le temps d'élever mes enfants jusqu'à leur entrée à
                l'école.
              </div>
            </div>
          </li>

          {/* <!-- Item 4 --> */}
          <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">Divers postes de secrétariat</span>
                <span className="time-wrapper">
                  <span className="time">2003 - 2008</span>
                </span>
              </div>
              <div className="desc">
                J'ai exercé majoritairement le métier de secrétaire polyvalente
                dans plusieurs entreprises sur des contrats en CDD.
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Body6;
