import React from "react";

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      t: new Date().toLocaleTimeString(),
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(this.runClock.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  runClock() {
    this.setState({ t: new Date().toLocaleTimeString() });
  }

  render() {
    return (
      <div className="clock">
        <h1>Il est {this.state.t} ! </h1>
        <h1 className="txt">L'heure d'embaucher Marie-Noelle Hostein</h1>
      </div>
    );
  }
}

export default Clock;
