import React from "react";
import AppRouter from "./router";
import "./scss/App.scss";

class App extends React.Component {
  render() {
    return (
      <>
        <AppRouter />
      </>
    );
  }
}

export default App;
