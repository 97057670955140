import React from "react";
import Navbar from "../components/Navbar/navbar";
import Body1 from "../components/body/body";
import Body2 from "../components/body/Body2";
import Body3 from "../components/body/Body3";
import Body4 from "../components/body/Body4";
import Body5 from "../components/body/Body5";
import Body6 from "../components/body/Body6";
import Body7 from "../components/body/Body7";
import Footer from "../components/Footer/footer";
import { useEffect } from "react";

const Main = () => {
  useEffect(() => {
    window.addEventListener("hashchange", () => {
      console.log("caca");
    });
  }, []);

  return (
    <>
      <Navbar />
      <div id="app-content">
        <Body1 />
        <Body2 />
        <Body3 />
        <Body4 />
        <Body5 />
        <Body6 />
        <Body7 />
        <Footer />
      </div>
    </>
  );
};

export default Main;
