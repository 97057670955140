import React from "react";

const Footer = () => (
  <>
    <div id="footer">
      <div className="boxFooter">
        <a
          href="https://www.linkedin.com/in/marie-noelle-hostein-4196875b/"
          target="_blank"
          title="LinkedIn"
        >
          <img src="/assets/images/linkedin.svg" width="50" height="50"></img>
        </a>
        <a
          href="https://gitlab.com/DeveloppeuseMnHostein"
          target="_blank"
          title="Gitlab"
        >
          <img src="/assets/images/gitlab.svg" width="50" height="50"></img>
        </a>
        <a href="#contact" title="Contact">
          <img src="/assets/images/portrait9.png" width="50" height="50"></img>
        </a>
      </div>
    </div>
  </>
);

export default Footer;
