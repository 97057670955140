import React from "react";

const Body5 = () => (
  <section id="diploma">
    <div className="pageBulles">
      <div className="titre">
        <h1 className="title title1">Mes diplômes</h1>
      </div>
      <div className="bulles">
        <div className="stage">
          <figure className="ball bubble bubble1">
            <div className="texte">
              Titre professionnel Developpeur Web et web mobile<br></br>2022
            </div>
          </figure>
        </div>

        <div className="stage">
          <figure className="ball bubble bubble2">
            <div className="texte">
              TOEIC <br></br> 895 points<br></br> B2
            </div>
          </figure>
        </div>

        <div className="stage">
          <figure className="ball bubble bubble3">
            <div className="texte">
              BTS Assistante de Gestion PME/PMI- 2003 <br></br>
            </div>
          </figure>
        </div>

        <div className="stage">
          <figure className="ball bubble bubble4">
            <div className="texte">
              BAC STT ACA<br></br> 2000
            </div>
          </figure>
        </div>

        <div className="stage">
          <figure className="ball bubble bubble5">
            <div className="texte">
              BEP CAS<br></br>1998
            </div>
          </figure>
        </div>
      </div>
    </div>
  </section>
);

export default Body5;
