import React from "react";

const Body7 = () => (
  <section id="contact">
    <div className="container">
      <div className="partieIllus">
        <div className="title title1">Me contacter</div>
        <img
          className="image contact"
          src="/assets/images/contactVtransp.png"
          alt="illustration de moi attendant un appel pour du travail"
        ></img>
      </div>
      <div className="partieTexte">
        <div>
          <div className="title title2">Marie-Noëlle Hostein </div>
        </div>
        <div className="box">
          <div className="mailto">
            <a href="mailto:email@example.com">Contactez-moi</a> à l'adresse
            suivante : mn.hostein@outlook.com
          </div>
        </div>
        <div className="box box-link">
          <div>
            <a
              href="https://gitlab.com/DeveloppeuseMnHostein"
              target="_blank"
              rel="noreferrer"
              title="Gitlab"
            >
              Gitlab
            </a>
          </div>
        </div>
        <div className="box box-link">
          <div>
            <a
              href="https://www.linkedin.com/in/marie-noelle-hostein-4196875b/"
              target="_blank"
              title="LinkedIn"
            >
              Lien vers Linkedin
            </a>
          </div>
        </div>
        <div className="box box-link">
          <div>
            <a href="./assets/images/CV_MN_HOSTEIN.pdf" target="_blank">
              Télécharger mon cv papier
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Body7;
