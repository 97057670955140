import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../pages/main";
import Portfolio from "../pages/portfolio";

const AppRouter = () => {
  return (
    <Routes>
      <Route index element={<Main />} />
      <Route path="/portfolio" element={<Portfolio />} />
    </Routes>
  );
};

export default AppRouter;
