import React from "react";
import Clock from "../Clock/clock";
import { Link } from "react-router-dom";
import { useState } from "react";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const activeLinkClassName = "nav-link-active";

const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation();

  console.log(location);

  const links = [
    { display: "Accueil", path: "#firstPage" },
    { display: "Présentation", path: "#presentation" },
    { display: "Langages", path: "#languages" },
    { display: "Formations", path: "#diploma" },
    { display: "Parcours", path: "#journey" },
  ];

  const toggleMenuVisible = () => {
    setMenuVisible((oldMenuVisible) => !oldMenuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  // Render

  const renderLinks = () => {
    const isActive = (hash) => {
      return location.hash === hash;
    };

    return links.map((link, index) => {
      return (
        <li
          key={index}
          className="navbar--link-item"
          onClick={() => {
            // Reverse le booleen menuVisible
            closeMenu();
          }}
        >
          <NavHashLink
            smooth
            // className={isActive(link.path) ? "active" : ""}
            className={() => (isActive(link.path) ? activeLinkClassName : "")}
            to={`/${link.path}`}
          >
            {link.display}
          </NavHashLink>
        </li>
      );
    });
  };

  return (
    <div className="navbar">
      <Clock />
      <nav>
        <div className="navbar--logo-holder" onClick={toggleMenuVisible}>
          <img
            src="/assets/images/Frame.svg"
            alt="logo"
            className="navbar--logo"
          />
          <h1 className="word_menu">Menu</h1>
        </div>
        <ul
          className={`navbar--menu-list ${menuVisible ? "visible" : "hidden"}`}
        >
          {renderLinks()}
          <li className="navbar--link-item" onClick={closeMenu}>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeLinkClassName : ""
              }
              to="/portfolio"
            >
              Portefolio
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
