import React from "react";

const PortfolioItem = ({ title, imgSrc, imgAlt, description, link }) => {
  return (
    <div className="card">
      <h2>{title}</h2>
      <a href={link}>
        <img src={imgSrc} alt={imgAlt} />
      </a>

      <h5>{description}</h5>
    </div>
  );
};

export default PortfolioItem;
