import React from "react";

const Body4 = () => (
  <section id="search">
    <div className="pageUnique">
      <img
        className="image illusDip"
        src="/assets/images/cherche.png"
        alt="illustration de moi montrant les diplomes"
      ></img>
      <div className="boxes">
        <div className="title title2">
          Un poste de Développeuse Web<br></br>
        </div>
        <p>
          Une entreprise qui me prendrait en contrat de professionnalisation
          pour le diplome Concepteur Développeur d'Application à partir de
          septembre 2022. Le rythme est de 3 semaines en entreprise et 1 semaine
          en formation.<br></br>
          Motivée et enthousiaste, je serai ravie de mettre mes compétences
          nouvellement acquise à votre disposition !
        </p>
      </div>
      {/* <a className="btn btn-white" href="#contact">Contact</a> */}
    </div>
  </section>
);

export default Body4;
