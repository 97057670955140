import React from "react";
import Navbar from "../components/Navbar/navbar";
import PortfolioItem from "../components/PortfolioItem/PortfolioItem";

const projects = [
  {
    title: "Calculatrice en JS",
    imgSrc: "/assets/images/calculette.png",
    imgAlt: "calculatrice",
    description: "Une calculatrice en JS",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/Calculatrice/",
  },
  {
    title: "Intersport",
    imgSrc: "/assets/images/intersport.png",
    imgAlt: "publicité pour intersport",
    description: "Publicité pour intersport",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/Intersport/",
  },
  {
    title: "Jeu Mémo",
    imgSrc: "/assets/images/memory.png",
    imgAlt: "jeu mémory",
    description: "Jeu mémory en JS",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/JeuMemo/",
  },
  {
    title: "Les blagues de toto",
    imgSrc: "/assets/images/blagues.png",
    imgAlt: "vignette de l'api",
    description: "Une api déroulant des blagues d'un goût douteux",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/apiBlague/",
  },
  {
    title: "Des bulles en balade",
    imgSrc: "/assets/images/bulles.png",
    imgAlt: "des bulles de couleurs",
    description: "Des bulles flottantes réalisées en JS",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/bulles/",
  },
  {
    title: "Logiciel de caisse - Bistrot",
    imgSrc: "/assets/images/caissecafe.png",
    imgAlt: "extrait de code",
    description: "programme écrit en PHP",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/caisse_cafe/caisseducafe.html",
  },
  {
    title: "Logiciel de caisse - Cinema",
    imgSrc: "/assets/images/caissecinema.png",
    imgAlt: "extrait de code",
    description: "programme écrit en PHP",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/caisse_cinema/",
  },
  {
    title: "Devinez mon nombre",
    imgSrc: "/assets/images/caissecinema.png",
    imgAlt: "extrait de code",
    description: "Petit jeu pour faire deviner un nombre",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/devine/",
  },
  {
    title: "SOS Fantôme",
    imgSrc: "/assets/images/fantome.png",
    imgAlt: "un petit fantome qui flotte",
    description: "Animation en CSS d'un petit fantôme qui flotte",
    link: "https://portefolio.marie-noelle-hostein.labo-ve.fr/fantome/",
  },
];

const Portfolio = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <h1 className="mainTitle">Portefolio</h1>
        {projects.map((project) => {
          return <PortfolioItem {...project} />;
        })}
      </div>
    </>
  );
};

export default Portfolio;
