import React from "react";

const Body3 = () => (
  <section id="languages">
    <div className="container">
      <div className="partieTexte">
        <div className="box box1">
          <h3>FRONT END</h3>
          <div className="row">Html/Css</div>
          <div className="row">Sass</div>
          <div className="row">Bootstrap</div>
        </div>
        <div className="box box2">
          <h3>BACK END</h3>
          <div className="row">PHP</div>
          <div className="row">Javascript</div>
          <div className="row">NodeJs</div>
        </div>
        <div className="box box3">
          <h3>FRAMEWORK</h3>
          <div className="row">Symfony</div>
          <div className="row">Wordpress</div>
          <div className="row">Express</div>
        </div>
        <div className="box box4">
          <h3>OUTILS SYSTEMES</h3>
          <div className="row">Git</div>
          <div className="row">Linux & Windows</div>
          <div className="row">Visual Studio Code</div>
          <div className="row">Figma</div>
        </div>
      </div>

      <div className="partieIllus">
        <img
          className="image jongle"
          src="/assets/images/jongleVF.png"
          alt="illustration me representant jonglant avec les langages"
        ></img>
      </div>
    </div>
  </section>
);

export default Body3;
